import Image from "next/image";
import { Container } from "reactstrap";
import {
  MiddleContantImg,
  MiddleContantInner,
  MiddleContantMain,
  MiddleContantText,
} from "../../StyledComponents/TeamStyled";
import DobbleQuotes from "../svg/DobbleQuotes";
import SmallRoundSvgIcon from "../svg/UIUXDesign/SmallRoundSvgIcon";
import VerifiedByDesignRushSvgGray from "../svg/VerifiedByDesignRushSvgGray";

export default function GlobalQuotes() {
  return (
    <Container className="w-full overflow-hidden p-b-section p-t-section ">
      <div
      // initial={{ opacity: 0, y: 200 }}
      // transition={{ duration: 0.5, ease: [0, 0.71, 0.2, 1.01] }}
      // animate={{ opacity: 1, y: 0 }}
      >
        <MiddleContantMain className="benefits-card-two">
          <MiddleContantInner>
            <DobbleQuotes />
          </MiddleContantInner>
          <MiddleContantText className="merriweather-regular">
            Their timeliness and their solid project management process are
            hallmarks of their work. Above all, IT SERVICES INDIA is committed,
            flexible, and{" "}
            <span className="relative">
              creative
              <span className="absolute -bottom-[3px] lg:-bottom-[3px] -rotate-2 left-[0px] right-[0px]">
                <SmallRoundSvgIcon />
              </span>
            </span>{" "}
            which allows them to establish a collaborative and fruitful
            partnership. We were impressed that
            <br className="hidden lg:block" /> IT SERVICES INDIA.
          </MiddleContantText>
          <MiddleContantImg>
            <Image
              src="https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Ariane-Gorin-user-avatar.jpg"
              width={81}
              height={81}
              alt="Ariane Gorin"
              className="rounded-[50%]"
              loading="lazy"
            />
          </MiddleContantImg>
          <MiddleContantInner>
            Ariane Gorin / CEO
             <div className="xl:w-[10%] md:w-[16%] w-[26%] logo-expodia">
              <Image
                src="https://wpapi.itservicesindia.com/wp-content/uploads/2025/04/expedia-Group-Logo.png"
                width={70}
                height={50}
                alt="expedia-Group-Logo"
                className="w-full"
              />
            </div>
          </MiddleContantInner>
          <MiddleContantInner className="!p-0">
            {/* <CargilIcon /> */}
            <VerifiedByDesignRushSvgGray />
          </MiddleContantInner>
        </MiddleContantMain>
      </div>
    </Container>
  );
}
